<style type="text/css">
  .model-btns {
    display: flex; flex-direction: row; align-items: center; justify-content: center;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .dlg-success {
    width: 437px;
  }
  .dlg-items {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
  }
</style>
    
<div>
  <div class="top20 dlg-items">
    <img class="bottom20" nz-image width="83px" height="83px" src="/assets/img/ic_success.png" />
  </div>

  <div class="title dlg-items bottom20">Shipment Successfully Created</div>

  <div class="dlg-items">Order ID - {{warpId}}</div>

  <div class="dlg-items"><a [href]="getTrackingLink()" target="_blank">{{getTrackingLink()}}</a>
    <span nz-icon nzType="copy" nzTheme="outline" (click)="copyTrackingLink()" nz-tooltip
    nzTooltipTitle="Copy Tracking URL" style="cursor: pointer; margin-left: 8px;"></span>
  </div>

  <div class="dlg-items bottom20"><a (click)="openOrder()">View Order</a></div>

  <div class="bottom20 model-btns">
    <button nz-button nzType="default" style="width: 202px;" (click)="createNewShipment()">Create New Shipment</button>
    <button nz-button nzType="primary" style="width: 70px; margin-left: 15px;" (click)="onDone()">Done</button>
  </div>
</div>
    