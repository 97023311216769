import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { DataorchService } from '@services/dataorch.service';
import { environment } from '@env/environment';

@Component({
  selector: '[client-selector]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class GlobalSearchComponent extends BaseComponent implements AfterViewInit {
    dataorch: DataorchService
    types: string[] = ['shipment', 'job', 'client', 'carrier']

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    key: string = null

    onKeyDown(event) {
        if (event.key != 'Enter') return
        this.onSearch()
    }

    onSearch() {
        if (!this.key) return

        this.onSearchShipment()
        this.onSearchJob()
    }

    shipments: any = null
    searchingShipment: boolean = false
    onSearchShipment() {
        const url = `${environment.backendUrl}/v2/orders?skip=0&limit=5&search=${this.key}`
        this.searchingShipment = true
        this.api.GET(url).subscribe({
            next: (res) => {
                this.searchingShipment = false
                this.shipments = res.data
                this.shipments.key = this.key
            },
            error: (err) => {
                this.searchingShipment = false
                this.shipments = {}
            }
        })
    }


    jobs: any = null
    searchingJob: boolean = false
    onSearchJob() {
        // https://gw.wearewarp.com/api/v1/jobs/list/listForDispatchScreen?skip=0&limit=10&search=0012-2413
        this.searchingJob = true
        const url = `${environment.backendUrl}/v1/jobs/list/listForDispatchScreen?skip=0&limit=5&search=${this.key}`
        this.api.GET(url).subscribe({
            next: (res) => {
                this.jobs = res.data
                this.jobs.key = this.key
                this.searchingJob = false
            },
            error: (err) => {
                this.searchingJob = false
                this.jobs = {}
            }
        })
    }
}